import { Component, OnInit } from '@angular/core';
import {StoresService} from '../../../services/store/stores.service';
import {NgForm} from '@angular/forms';
import {ResponseContentType} from '@angular/http';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent implements OnInit {
  successMessage: string;
  fileToUpload: any;
  formData: FormData = new FormData();

  constructor(
    private stores: StoresService,
    private http: HttpClient,
  ) { }

  ngOnInit() {
  }

  public onFileChange(event) {
    if (this.formData.has('stores')) {
      this.formData.delete('stores');
    }
    const fileList: FileList = event.target.files;
    const file: File = fileList[0];
    this.formData.append('stores', file);

    this.fileToUpload = file;
  }

  downloadFile(contentType: string) {
    return this.http.get(
      environment.apiUrl + '/api/stores/export',
      {
        headers: new HttpHeaders().append('Content-Type', contentType),
        responseType: 'blob', observe: 'body'
      }
    );
  }

  downloadF(contentType: string, filename: string) {
    return this.downloadFile(contentType).subscribe(
      res => {
        console.log('start download:', res);
        const url = window.URL.createObjectURL(res);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.setAttribute('style', 'display: none');
        a.href = url;
        a.download = filename + '.' + contentType;
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove(); // remove the element
      }, error => {
        console.log('download error:', JSON.stringify(error));
      }, () => {
        console.log('Completed file download.');
      });
  }

  public importStores(): void {
    if (this.formData.has('stores')) {
      console.log(this.formData.get('stores'));
      this.stores.importStores(this.formData).subscribe(res => {
        console.log(res);
      });
    }
  }

  public submitForm(form: NgForm): void {
    this.importStores();
  }
}
