import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {Category} from '../../models/categories/category';
import {AuthService} from '../../services/auth/auth.service';
import {CategoryService} from '../../services/category/category.service';
import {LangService} from '../../services/lang/lang.service';

@Component({
  selector: 'app-categorieen',
  templateUrl: './categorieen.component.html',
  styleUrls: ['./categorieen.component.scss']
})
export class CategorieenComponent implements OnInit {
  selectedLang: string;
  categoryList: Category[];
  constructor(
    private category: CategoryService,
    private lang: LangService
  ) { }

  ngOnInit() {
    this.selectedLang = 'nl';
    this.getCatgories();
  }

  public switchLang(): void {
    // console.log('Lang: ' + this.selectedLang);
    this.categoryList.forEach(cat => {
      cat = this.lang.getCategoryTranslation(this.selectedLang, cat);
    });
  }

  public getCatgories(): void {
    this.category.getCategories().subscribe( categories => {
      this.categoryList = categories;
      // console.log(this.categoryList);
    });
  }
}
