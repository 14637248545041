import { Component, OnInit } from '@angular/core';
import { DealsService } from '../../services/deals/deals.service';
import { Timeslot } from '../../models/timeslots/timeslots';

@Component({
  selector: 'app-tijdvakken',
  templateUrl: './tijdsbestek.component.html',
  styleUrls: ['./tijdsbestek.component.scss']
})
export class TijdsBestekComponent implements OnInit {
  availableTimeslots: Timeslot[] = [];
  constructor(private deals: DealsService) { }

  ngOnInit() {
    
    this.getAvailableTimeslots();
  }


  public getAvailableTimeslots(): void {
    this.deals.getTimeslots().subscribe( res => { this.availableTimeslots = res; });
  }
  
}
