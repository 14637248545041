import { Component, OnInit } from '@angular/core';
import {Actie, ActieSchedule} from '../../../models/acties/actie';
import {ActiesService} from '../../../services/acties/acties.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Category} from '../../../models/categories/category';
import * as $ from 'jquery';
import {CategoryService} from '../../../services/category/category.service';
import {Timeslot} from '../../../models/timeslots/timeslots';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NgForm} from '@angular/forms';
import {LangService} from '../../../services/lang/lang.service';
import {FormdataService} from '../../../services/formdata/formdata.service';
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-acties-aanpassen',
  templateUrl: './acties-aanpassen.component.html',
  styleUrls: ['./acties-aanpassen.component.scss']
})
export class ActiesAanpassenComponent implements OnInit {
  isNewActie: boolean;
  formData: FormData = new FormData();
  environment = environment;

  selectedActie: Actie = new Actie();
  selectedSchedule: ActieSchedule = new ActieSchedule();
  selectedCategories: any[] = [];
  selectedTimeslots: any[] = [];

  fileToUpload: File;
  imageUrl: string;
  scheduleData: FormData = new FormData();

  availableCategories: Category[] = [];
  availableTimeslots: Timeslot[] = [];

  errorMessage: string;
  successMessage: string;

  openedModal: any;
  selectedLang: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private acties: ActiesService,
    private categories: CategoryService,
    private modalService: NgbModal,
    private lang: LangService,
    private formdata: FormdataService,
  ) { }

  ngOnInit() {
    this.selectedLang = 'nl';
    if (this.router.url === '/actie-aanmaken') {
      // New actie.
      this.isNewActie = true;
    } else {
      // Edit actie.
      this.isNewActie = false;
      this.getSelectedActie();
    }

  }

  public getSelectedActie(): void {
    this.route.params.subscribe(params => {
      const paramId = +params['id'];
      this.acties.getActieById(paramId).subscribe(res => {
        this.selectedActie = res;
        // console.log(this.selectedActie);
        this.imageUrl = this.selectedActie.image;
        this.selectedActie.translation_title = this.lang.getActieTranslationTitle(this.selectedLang, this.selectedActie);
        this.selectedActie.translation_description = this.lang.getActieTranslationDescription(this.selectedLang, this.selectedActie);
      });
    });
  }

  public onFileChange(event) {
    const fileList: FileList = event.target.files;
    const file: File = fileList[0];
    this.formData.append('image', file);

    this.fileToUpload = file;
    const reader = new FileReader();
    reader.onload = (event2: any) => {
      this.imageUrl = event2.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  public createActie(): void {
    this.formData.append('title', this.selectedActie.title);



    if (typeof this.selectedActie.description === 'undefined') {
      this.selectedActie.description = '';
    }

    this.formData.append('description', this.selectedActie.description);
    this.formData.append('barcode', this.selectedActie.barcode);
    this.formData.append('categories', JSON.stringify(this.selectedCategories));
    this.formData.append('link', this.selectedActie.link);

    this.formData.append('image', this.fileToUpload);
    // console.log(this.formData.get('categories'));
    // Create actie
    this.acties.createActie(this.formData).subscribe(createActie => {
      this.formData = new FormData();
      this.router.navigate(['/acties/']);
    });
    this.formData = new FormData();
  }

  public editActie(): void {
    if (this.selectedLang === 'en') {
      const translations = [{
        lang: 'en',
        title: this.selectedActie.translation_title,
        description: this.selectedActie.translation_description
      }];
      if (this.formData.has('translations')) {
        this.formData.delete('translations');
      }
      this.formData.append('translations', JSON.stringify(translations));
    }
    this.formData.append('title', this.selectedActie.title);
    this.formData.append('link', this.selectedActie.link);

    if (this.selectedActie.description === 'undefined') {
      this.selectedActie.description = '';
    }

    this.formData.append('description', this.selectedActie.description);
    this.formData.append('barcode', this.selectedActie.barcode);
    
    // Edit actie
    this.acties.editActie(this.selectedActie.id, this.formData).subscribe(res => {
      this.successMessage = 'De actie is aangepast!';
      this.getSelectedActie();
    }, error => {
      this.errorMessage = error.error.message;
    });
    this.formData = new FormData();
    this.getSelectedActie();

  }

  public onSubmit(form: NgForm) {
    if (this.isNewActie) {
      this.createActie();
    } else {
      this.editActie();
    }
  }

  public openDeleteModal(content): void {
    this.openedModal = this.modalService.open(content);
  }

  public deleteActie(): void {
    this.acties.deleteActie(this.selectedActie.id).subscribe(res => {
      this.openedModal.close();
      this.router.navigate(['/acties']);
    });
  }

  public switchLang(): void {
    this.selectedActie.translation_title = this.lang.getActieTranslationTitle(this.selectedLang, this.selectedActie);
    this.selectedActie.translation_description = this.lang.getActieTranslationDescription(this.selectedLang, this.selectedActie);
  }
}
