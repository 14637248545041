import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/Observable';
import {Deal, DealSchedule} from '../../models/deals/deal';
import {Category} from '../../models/categories/category';
import {Timeslot} from '../../models/timeslots/timeslots';

@Injectable()
export class DealsService {

  constructor(
    private api: ApiService
  ) { }

  public getDealById(id: number): Observable<Deal> {
    return this.api.get('/api/deals/' + id);
  }

  public getCategoriesByDealId(id: number): Observable<Category[]> {
    return this.api.get('/api/deals/' + id + '/category');
  }

  public getScheduleByDealId(id: number): Observable<DealSchedule> {
    return this.api.get('/api/deals/' + id + '/schedule');
  }

  public createDeal(formData: any): Observable<any> {
    return this.api.post('/api/deals', formData);
  }

  public deleteDeal(dealId: number): Observable<any> {
    return this.api.delete('/api/deals/' + dealId);
  }

  public addCategoryToDeal(dealId: number, catId: number): Observable<any> {
    return this.api.post('/api/deals/' + dealId + '/category', {category: catId});
  }

  public removeCategoryFromDeal(dealId: number, catId: number): Observable<any> {
    return this.api.delete('/v2/deals/' + dealId + '/category/' + catId);
  }

  public addScheduleToDeal(dealId: number, formData: any) {
    return this.api.post('/api/deals/' + dealId + '/schedule', formData);
  }

  public getTimeslots(): Observable<Timeslot[]> {
    return this.api.get('/api/schedules/timeslots');
  }

  public getTimeslotByID(id: number): Observable<Timeslot> {
    return this.api.get('/api/schedules/timeslot/' + id);
  }

  public createTimeslot(formData: any){
    return this.api.post('/api/schedules/timeslots', formData); 
  }
  
  public deleteTimeslot(timeslotId: number): Observable<any> {
    return this.api.delete('/api/schedules/timeslot/' + timeslotId);
  }

  public editTimeslot(timeslotId: number, formData:any): Observable<any> {
    return this.api.post('/api/schedules/timeslot/' + timeslotId, formData);
  }

  public editDeal(dealId: number, formData: any): Observable<any> {
    return this.api.post('/api/deals/' + dealId + '/edit', formData);
  }

  public editDealSchedule(dealId: number, formData: any): Observable<any> {
    return this.api.post('/api/deals/' + dealId + '/schedule/edit', formData);
  }

  public setPinStatus(dealId: number, status: number): Observable<any> {
    return this.api.post('/api/deals/' + dealId + '/setpin', {status: status});
  }
}
