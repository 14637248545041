import { Injectable } from '@angular/core';
import {Category} from '../../models/categories/category';
import {Deal} from '../../models/deals/deal';
import {Actie} from '../../models/acties/actie';

@Injectable()
export class LangService {

  constructor() { }

  public getCategoryTranslation(lang: string, cat: Category): Category {
    cat.all_translations.forEach(translation => {
      if (translation.lang === lang) {
        cat.translation_title = translation.title;
      }
    });
    return cat;
  }

  public getCategoryTranslationTitle(lang: string, cat: Category): string {
    const translatedCat = this.getCategoryTranslation(lang, cat);
    return translatedCat.translation_title;
  }

  public getDealTranslation(lang: string, deal: Deal): Deal {
    deal.all_translations.forEach(translation => {
      if (translation.lang === lang) {
        deal.translation_title = translation.title;
        deal.translation_description = translation.description;
      }
    });
    return deal;
  }

  public getDealTranslationTitle(lang: string, deal: Deal): string {
    const translatedDeal = this.getDealTranslation(lang, deal);
    return translatedDeal.translation_title;
  }

  public getDealTranslationDescription(lang: string, deal: Deal): string {
    const translatedDeal = this.getDealTranslation(lang, deal);
    return translatedDeal.translation_description;
  }

  public getActieTranslation(lang: string, actie: Actie): Actie {
    actie.all_translations.forEach(translation => {
      if (translation.lang === lang) {
        actie.translation_title = translation.title;
        actie.translation_description = translation.description;
      }
    });
    return actie;
  }

  public getActieTranslationTitle(lang: string, actie: Actie): string {
    const translatedActie = this.getActieTranslation(lang, actie);
    return translatedActie.translation_title;
  }

  public getActieTranslationDescription(lang: string, actie: Actie): string {
    const translatedActie = this.getActieTranslation(lang, actie);
    return translatedActie.translation_description;
  }
}
