import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { Actie } from '../../models/acties/actie';
import { LangService } from '../../services/lang/lang.service';
import { ActiesService } from '../../services/acties/acties.service';
import { OnesignalService } from '../../services/onesignal/onesignal.service';

@Component({
  selector: 'app-acties',
  templateUrl: './acties.component.html',
  styleUrls: ['./acties.component.scss']
})
export class ActiesComponent implements OnInit {
  selectedLang: string;
  actiesList: Actie[];
  constructor(
    private api: ApiService,
    private acties: ActiesService,
    private lang: LangService,
    private onesignal: OnesignalService,
  ) { }

  ngOnInit() {
    this.selectedLang = 'nl';
    this.getActies();
    // this.onesignal.sendMessage(['test', 'meme']);
  }

  public switchLang(): void {
    console.log('Lang: ' + this.selectedLang);
    this.actiesList.forEach(actie => {
      actie = this.lang.getActieTranslation(this.selectedLang, actie);
    });
  }

  public togglePinnedActie(actieId: number, currentStatus: number): void {
    let newStatus: number;
    if (currentStatus === 0) {
      newStatus = 1;
    } else {
      newStatus = 0;
    }
    this.acties.setPinStatus(actieId, newStatus).subscribe(res => {
      console.log(res);
      this.getActies();
    });
  }

  public getActies() {
    this.api.getActies().subscribe(acties => {
      this.actiesList = acties;
    });
  }

  public updateWeight(id, weight) {
    this.api.updateWeight(id, weight).subscribe(res => {
      window.location.reload();
    });
  }
}
