import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppComponent } from './app.component';
import {RouterModule} from '@angular/router';
import { NavbarComponentComponent } from './components/navbar-component/navbar-component.component';
import {ApiService} from './services/api/api.service';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { OverzichtComponent } from './pages/overzicht/overzicht.component';
import { CategorieenComponent } from './pages/categorieen/categorieen.component';
import { DealsComponent } from './pages/deals/deals.component';
import { ActiesComponent } from './pages/acties/acties.component';
import { WinkelsComponent } from './pages/winkels/winkels.component';
import { GebruikersComponent } from './pages/gebruikers/gebruikers.component';
import {AuthGuard} from './services/auth/auth.guard';
import { NavbarComponent } from './components/navbar/navbar.component';
import { LoginComponent } from './pages/login/login.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AuthService} from './services/auth/auth.service';
import {ApiInterceptor} from './services/api/api-interceptor';
import { CategorieAanpassenComponent } from './pages/categorieen/categorie-aanpassen/categorie-aanpassen.component';
import {CategoryService} from './services/category/category.service';
import { ModalComponent } from './components/modal/modal.component';
import { DealsAanpassenComponent } from './pages/deals/deals-aanpassen/deals-aanpassen.component';
import { ActiesAanpassenComponent } from './pages/acties/acties-aanpassen/acties-aanpassen.component';
import {DealsService} from './services/deals/deals.service';
import {ActiesService} from './services/acties/acties.service';
import { GebruikerDetailsComponent } from './pages/gebruikers/gebruiker-details/gebruiker-details.component';
import {UsersService} from './services/users/users.service';
import { WinkelsAanpassenComponent } from './pages/winkels/winkels-aanpassen/winkels-aanpassen.component';
import {StoresService} from './services/store/stores.service';
import { DealsToevoegenComponent } from './pages/deals/deals-toevoegen/deals-toevoegen.component';
import { ActiesToevoegenComponent } from './pages/acties/acties-toevoegen/acties-toevoegen.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {TimeslotService} from './services/timeslot/timeslot.service';
import {NgbModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LangService} from './services/lang/lang.service';
import {FormdataService} from './services/formdata/formdata.service';
import { TijdsBestekComponent } from './pages/tijdsbestek/tijdsbestek.component';
import { TijdsbestekAanpassenComponent } from './pages/tijdsbestek/tijdsbestek-aanpassen/tijdsbestek-aanpassen.component';
import { OnesignalService } from './services/onesignal/onesignal.service';
import { ImportComponent } from './pages/winkels/import/import.component';
import {DeviatinghoursService} from './services/deviatinghours/deviatinghours.service';

/** Dutch names in english code suck, but it's for urls and names! **/
const appRoutes = [
  // {path: '', component: SymptomsSubmissionsComponent, canActivate: [AuthGuard]},
  {path: '', component: OverzichtComponent, canActivate: [AuthGuard]},
  {path: 'overzicht', component: OverzichtComponent, canActivate: [AuthGuard]},

  {path: 'categorieen', component: CategorieenComponent, canActivate: [AuthGuard]},
  {path: 'categorie-aanpassen/:id', component: CategorieAanpassenComponent, canActivate: [AuthGuard]},
  {path: 'categorie-aanmaken', component: CategorieAanpassenComponent, canActivate: [AuthGuard]},

  {path: 'deals', component: DealsComponent, canActivate: [AuthGuard]},
  {path: 'deals-aanpassen/:id', component: DealsAanpassenComponent, canActivate: [AuthGuard]},
  {path: 'deal-aanmaken', component: DealsAanpassenComponent, canActivate: [AuthGuard]},

  {path: 'acties', component: ActiesComponent, canActivate: [AuthGuard]},
  {path: 'acties-aanpassen/:id', component: ActiesAanpassenComponent, canActivate: [AuthGuard]},
  {path: 'actie-aanmaken', component: ActiesAanpassenComponent, canActivate: [AuthGuard]},

  {path: 'tijdsbestek', component: TijdsBestekComponent, canActivate: [AuthGuard]},
  {path: 'tijdsbestek-aanpassen/:id', component: TijdsbestekAanpassenComponent, canActivate: [AuthGuard]},
  {path: 'tijdsbestek-aanmaken', component: TijdsbestekAanpassenComponent, canActivate: [AuthGuard]},

  {path: 'winkels', component: WinkelsComponent, canActivate: [AuthGuard]},
  {path: 'winkels-aanpassen/:id', component: WinkelsAanpassenComponent, canActivate: [AuthGuard]},
  {path: 'winkels-aanmaken', component: WinkelsAanpassenComponent, canActivate: [AuthGuard]},
  {path: 'winkel-import', component: ImportComponent, canActivate: [AuthGuard]},

  {path: 'gebruikers', component: GebruikersComponent, canActivate: [AuthGuard]},
  {path: 'gebruiker-details/:id', component: GebruikerDetailsComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent},
];

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponentComponent,
    OverzichtComponent,
    CategorieenComponent,
    DealsComponent,
    ActiesComponent,
    WinkelsComponent,
    GebruikersComponent,
    NavbarComponent,
    LoginComponent,
    CategorieAanpassenComponent,
    ModalComponent,
    DealsAanpassenComponent,
    ActiesAanpassenComponent,
    GebruikerDetailsComponent,
    WinkelsAanpassenComponent,
    DealsToevoegenComponent,
    ActiesToevoegenComponent,
    TijdsBestekComponent,
    TijdsbestekAanpassenComponent,
    ImportComponent,
  ],
  imports: [
    RouterModule.forRoot(
      appRoutes,
      {}
    ),
    NgbModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    ApiService,
    AuthService,
    AuthGuard,
    CategoryService,
    DealsService,
    ActiesService,
    UsersService,
    StoresService,
    FormsModule,
    TimeslotService,
    LangService,
    FormdataService,
    OnesignalService,
    DeviatinghoursService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
