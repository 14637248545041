import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {Deal} from '../../models/deals/deal';
import {LangService} from '../../services/lang/lang.service';
import {DealsService} from '../../services/deals/deals.service';
import { OnesignalService } from '../../services/onesignal/onesignal.service';

@Component({
  selector: 'app-deals',
  templateUrl: './deals.component.html',
  styleUrls: ['./deals.component.scss']
})
export class DealsComponent implements OnInit {
  selectedLang: string;
  dealsList: Deal[];
  constructor(
    private api: ApiService,
    private deals: DealsService,
    private lang: LangService,
    private onesignal: OnesignalService,
  ) { }

  ngOnInit() {
    this.selectedLang = 'nl';
    this.getDeals();
    // this.onesignal.sendMessage(['test', 'meme']);
  }

  public switchLang(): void {
    console.log('Lang: ' + this.selectedLang);
    this.dealsList.forEach(deal => {
      deal = this.lang.getDealTranslation(this.selectedLang, deal);
    });
  }

  public togglePinnedDeal(dealId: number, currentStatus: number): void {
    let newStatus: number;
    if (currentStatus === 0) {
      newStatus = 1;
    } else {
      newStatus = 0;
    }
    this.deals.setPinStatus(dealId, newStatus).subscribe(res => {
      console.log(res);
      this.getDeals();
    });
  }

  public getDeals() {
    this.api.getDeals().subscribe( deals => {
      this.dealsList = deals;
    });
  }
}
