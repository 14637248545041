import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acties-toevoegen',
  templateUrl: './acties-toevoegen.component.html',
  styleUrls: ['./acties-toevoegen.component.scss']
})
export class ActiesToevoegenComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
