import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/Observable';
import {Category} from '../../models/categories/category';

@Injectable()
export class CategoryService {

  constructor(
    private api: ApiService
  ) { }

  public getCategories(): Observable<Category[]> {
    return this.api.get('/api/categories');
  }

  public getCategoryById(id: number): Observable<Category> {
    return this.api.get('/api/categories/' + id);
  }

  public editCategoriesFormData(id: number, formData: FormData) {
    return this.api.post('/api/categories/' + id + '/edit', formData);
  }

  public editCategories(category: Category) {
    const body = {
      title: category.title,
      translations: category.translations
    };
    return this.api.post('/api/categories/' + category.id + '/edit', body);
  }

  public createCategories(formData: any) {
    return this.api.post('/api/categories', formData);
  }

  public deleteCategories(id: number): Observable<any> {
    return this.api.delete('/api/categories/' + id);
  }
}
