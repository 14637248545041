import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {Observable} from 'rxjs/Observable';
import { shop } from '@env/environment'; 
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-navbar-component',
  templateUrl: './navbar-component.component.html',
  styleUrls: ['./navbar-component.component.scss']
})
export class NavbarComponentComponent implements OnInit {

  environment = environment;
  isLoggedIn$: Observable<boolean>;
  shop = shop;

  constructor(
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.isLoggedIn$ = this.auth.isLoggedIn;
  }

  public logOut(): void {
    this.auth.logout();
  }
}
