import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class OnesignalService {
  
  constructor(
    public http: HttpClient,
  ) { }

  public sendMessage(contents: object, headings: object = {},  categories: string[] = [], segments: string[] = []): Observable<any>{

   let params = {
      app_id: environment.onesignalAppKey,
      included_segments: segments,
      filters: [
      ],
      contents: contents,
      headings: headings,
    }
    categories.forEach((value, index) => {
        params.filters.push({field: "tag", key: value, relation: "exists"});
        params.filters.push({"operator": "OR"});
    });

    console.log(params);

    return this.http.post(environment.onesignalUrl,JSON.stringify(params),{headers: {'Authorization': 'Basic ' + environment.onesignalApiKey,'Content-Type': 'application/json; charset=utf-8'}});
  }

}
