import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import { OnesignalService } from '../../services/onesignal/onesignal.service';
import { DealsService } from '../../services/deals/deals.service';
import { CategoryService } from '../../services/category/category.service';
import { Category } from '../../models/categories/category';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-overzicht',
  templateUrl: './overzicht.component.html',
  styleUrls: ['./overzicht.component.scss']
})
export class OverzichtComponent implements OnInit {
  gemaakteClaims: any[];
  loyaltyClaims: any[];
  categoryList: Category[];
  selectedCategories: any[];
  onesignalTitleText: string;
  onesignalTitleTextEn: string;
  onesignalMessageText: string;
  onesignalMessageTextEn: string;

  constructor(
    private api: ApiService,
    private onesignal: OnesignalService,
    private category: CategoryService,
  ) { }

  ngOnInit() {
    this.getClaims();
    this.getCatgories();
  }

  public getClaims(): void {
    this.api.getClaimsTotal().subscribe(res => {
      this.gemaakteClaims = res.total;
      this.loyaltyClaims = res.loyalty_total;
    });
  }

  public getCatgories(): void {
    this.category.getCategories().subscribe( categories => {
      this.categoryList = categories;
    });
  }
  public getLoyaltyClaims() {
    // this.gemaakteClaims.forEach(claim => {});
  }

  public submitForm(form: NgForm): void {
    this.onesignal.sendMessage({
      en: this.onesignalMessageTextEn,
      nl: this.onesignalMessageText
    }, {
      en: this.onesignalTitleTextEn,
      nl: this.onesignalTitleText
      }, this.selectedCategories, []).subscribe(res => {
          console.log(res);
      });
  }
}
