import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/Observable';
import {Timeslot} from '../../models/timeslots/timeslots';

@Injectable()
export class TimeslotService {

  constructor(
    private api: ApiService
  ) { }

  public createTimeslot(timeslot: Timeslot): Observable<any> {
    return this.api.post('/api/schedules/timeslots', timeslot);
  }

  public getTimeslots(): Observable<Timeslot> {
    return this.api.get('/api/schedules/timeslots');
  }

  // public removeTimeslot(): Observable<any> {}

  // public editTimeSlot(): Observable<any> {}
}
