import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {SparUser} from '../../models/users/user';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-gebruikers',
  templateUrl: './gebruikers.component.html',
  styleUrls: ['./gebruikers.component.scss']
})
export class GebruikersComponent implements OnInit {
  userList: SparUser[];
  limit: number;
  pageNum: number;
  surroundingPages: any;

  constructor(
    private api: ApiService
  ) {
    this.pageNum = 0;
    this.limit = environment.userlistLimit;

    this.surroundingPages = {
      currentPage: 0,
      previous: [],
      next: [1, 2, 3, 4, 5, 6, 7, 8],
    };
  }

  ngOnInit() {
    this.handlePage();
  }

  public getUsers(): void {
    const offset = this.pageNum * this.limit;
    this.api.getUsers(offset).subscribe( users => {
      this.userList = users;
    });
  }



  public handlePage() {
    this.getUsers();
    this.surroundingPages = {
      currentPage: this.pageNum,
      previous: [],
      next: [],
    };



    const previouscounter = this.pageNum < 1 ? 0 : (this.pageNum - 1);


    for ( let i = previouscounter; i > (previouscounter - 4); i--) {
      if (i < 0 ) {
        break;
      }
      this.surroundingPages.previous.push(i);
    }



    const nextCounter = this.pageNum < 1 ? this.pageNum + 1 : this.pageNum;

    for (let i = nextCounter; i < this.pageNum + 5; i++ ) {
      this.surroundingPages.next.push(i);
    }
    this.surroundingPages.previous.sort(this.sortNumber);
    this.surroundingPages.next.sort(this.sortNumber);

  }

  public setCurrentPage(pageNum: number) {
    this.pageNum = pageNum;
    this.handlePage();
  }

  public nextPage() {
    this.pageNum++;
    this.handlePage();
  }

  public previousPage() {
    if ( this.pageNum >= 1) {
      this.pageNum--;

      this.handlePage();
    }
  }

  private sortNumber(a, b) {
    return a - b;
  }
}
