export class SparUser {
  private _id: number;
  private _name: string;
  private _email: string;
  private _phone: string;
  private _street: string;
  private _city: string;
  private _housenumber: string;
  private _zipcode: string;
  private _push_messages: number;

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get phone(): string {
    return this._phone;
  }

  set phone(value: string) {
    this._phone = value;
  }

  get street(): string {
    return this._street;
  }

  set street(value: string) {
    this._street = value;
  }

  get city(): string {
    return this._city;
  }

  set city(value: string) {
    this._city = value;
  }

  get housenumber(): string {
    return this._housenumber;
  }

  set housenumber(value: string) {
    this._housenumber = value;
  }

  get zipcode(): string {
    return this._zipcode;
  }

  set zipcode(value: string) {
    this._zipcode = value;
  }

  get push_messages(): number {
    return this._push_messages;
  }

  set push_messages(value: number) {
    this._push_messages = value;
  }
}

export class LoginModel {
  private _email: string;
  private _password: string;

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get password(): string {
    return this._password;
  }

  set password(value: string) {
    this._password = value;
  }
}
