import { Injectable } from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {Store} from '../../models/stores/store';
import {ApiService} from '../api/api.service';

@Injectable()
export class StoresService {

  constructor(
    private api: ApiService
  ) { }

  public getStoreById(id: number): Observable<Store> {
    return this.api.get('/api/stores/' + id);
  }

  public editStore(id: number): Observable<any> {
    return this.api.post('/api/stores/' + id, );
  }

  public getStores(): Observable<Store[]> {
    return this.api.get('/api/stores');
  }

  public updateStore(storeId: number, formData: any): Observable<any> {
    console.log(formData.get('businesshours'));
    return this.api.post('/api/stores/' + storeId, formData);
  }

  public deleteStore(storeId: number): Observable<any> {
    return this.api.delete('/api/stores/' + storeId);
  }

  public createStore(formData: FormData): Observable<any> {
    return this.api.post('/api/stores', formData);
  }

  public importStores(formData: FormData): Observable<any> {
    return this.api.post('/api/stores/import', formData);
  }
}
