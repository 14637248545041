export class Category {
  private _id: number;
  private _title: string;
  private _image: any;
  private _subscribeable: number;
  private _all_translations: CategoryTranslations[];
  private _translation_title: string;
  private _translations: any;

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get image(): any {
    return this._image;
  }

  set image(value: any) {
    this._image = value;
  }

  get subscribeable(): number {
    return this._subscribeable;
  }

  set subscribeable(value: number) {
    this._subscribeable = value;
  }

  get all_translations(): CategoryTranslations[] {
    return this._all_translations;
  }

  set all_translations(value: CategoryTranslations[]) {
    this._all_translations = value;
  }

  get translation_title(): string {
    return this._translation_title;
  }

  set translation_title(value: string) {
    this._translation_title = value;
  }

  get translations(): any {
    return this._translations;
  }

  set translations(value: any) {
    this._translations = value;
  }
}

export class CategoryTranslations {
  private _id: number;
  private _category_id: number;
  private _lang: string;
  private _title: string;

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get category_id(): number {
    return this._category_id;
  }

  set category_id(value: number) {
    this._category_id = value;
  }

  get lang(): string {
    return this._lang;
  }

  set lang(value: string) {
    this._lang = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }
}
