import { Component, OnInit } from "@angular/core";
import { Deal, DealSchedule } from "../../../models/deals/deal";
import { DealsService } from "../../../services/deals/deals.service";
import { ActivatedRoute, Router } from "@angular/router";
import { Category } from "../../../models/categories/category";
import * as $ from "jquery";
import { CategoryService } from "../../../services/category/category.service";
import { Timeslot } from "../../../models/timeslots/timeslots";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgForm } from "@angular/forms";
import { LangService } from "../../../services/lang/lang.service";
import { FormdataService } from "../../../services/formdata/formdata.service";
import { environment } from "../../../../environments/environment";

@Component({
  selector: "app-deals-aanpassen",
  templateUrl: "./deals-aanpassen.component.html",
  styleUrls: ["./deals-aanpassen.component.scss"],
})
export class DealsAanpassenComponent implements OnInit {
  isNewDeal: boolean;
  formData: FormData = new FormData();
  environment = environment;

  selectedDeal: Deal = new Deal();
  selectedSchedule: DealSchedule = new DealSchedule();
  selectedCategories: any[] = [];
  selectedTimeslots: any[] = [];

  fileToUpload: File;
  imageUrl: string;
  scheduleData: FormData = new FormData();

  availableCategories: Category[] = [];
  availableTimeslots: Timeslot[] = [];

  errorMessage: string;
  successMessage: string;

  openedModal: any;
  selectedLang: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private deals: DealsService,
    private categories: CategoryService,
    private modalService: NgbModal,
    private lang: LangService,
    private formdata: FormdataService
  ) {}

  ngOnInit() {
    this.selectedLang = "nl";
    if (this.router.url === "/deal-aanmaken") {
      // New deal.
      this.isNewDeal = true;
    } else {
      // Edit deal.
      this.isNewDeal = false;
      this.getSelectedDeal();
    }

    this.getAvailableCategories();
    this.getAvailableTimeslots();
  }

  public getSelectedDeal(): void {
    this.route.params.subscribe((params) => {
      const paramId = +params["id"];
      this.deals.getDealById(paramId).subscribe((res) => {
        this.selectedDeal = res;
        // console.log(this.selectedDeal);
        this.imageUrl = this.selectedDeal.image;
        this.getSelectedCategories(res.id);
        this.getSchedule(res.id);
        this.selectedDeal.translation_title = this.lang.getDealTranslationTitle(
          this.selectedLang,
          this.selectedDeal
        );
        this.selectedDeal.translation_description =
          this.lang.getDealTranslationDescription(
            this.selectedLang,
            this.selectedDeal
          );
      });
    });
  }

  // Categories
  public getAvailableCategories(): void {
    this.categories.getCategories().subscribe((res) => {
      this.availableCategories = res;
    });
  }

  public categoryChange(event) {
    this.selectedCategories = event;
  }

  public getSelectedCategories(dealId: number): void {
    this.deals.getCategoriesByDealId(dealId).subscribe((cats) => {
      this.selectedDeal.categories = cats;
      const selectedCatArray = [];
      cats.forEach((category) => {
        selectedCatArray.push(category.id);
      });
      this.selectedCategories = selectedCatArray;
    });
  }

  // Schedule
  public getSchedule(dealId: number): void {
    this.deals.getScheduleByDealId(dealId).subscribe((schedule) => {
      if (schedule !== null) {
        this.selectedSchedule = schedule;
        this.selectedSchedule.days.forEach((day) => {
          $(".scheduleCheck[name=dealDays" + day.daynum + "]").prop(
            "checked",
            true
          );
        });
        const selectedTimeSlots = [];
        this.selectedSchedule.timeslots.forEach((timeslot) => {
          selectedTimeSlots.push(timeslot.id);
          this.selectedTimeslots = selectedTimeSlots;
        });
      }
    });
  }

  public onScheduleChange(): void {
    if (this.scheduleData.has("days")) {
      this.scheduleData.delete("days");
    }
    let values = "[";
    $("input.scheduleCheck:checked").each(function (index, element) {
      if (index === 0) {
        values += $(this).val();
      } else {
        values += "," + $(this).val();
      }
    });
    values += "]";
    this.scheduleData.append("days", values);
  }

  public getAvailableTimeslots(): void {
    this.deals.getTimeslots().subscribe((res) => {
      this.availableTimeslots = res;
    });
  }

  public timeslotChange(event) {
    this.selectedTimeslots = event;
  }

  public onValidChange(event): void {
    if (this.scheduleData.has("valid_from")) {
      this.scheduleData.delete("valid_from");
    }
    if (this.scheduleData.has("valid_until")) {
      this.scheduleData.delete("valid_until");
    }
    const from = $("#dealOnline").val().toString();
    const to = $("#dealOffline").val().toString();

    if (from.length !== 0) {
      this.scheduleData.append("valid_from", from);
    }
    if (to.length !== 0) {
      this.scheduleData.append("valid_until", to);
    }
  }

  // Other
  public addScheduleToDeal(dealId: number): void {
    this.scheduleData.append(
      "timeslots",
      JSON.stringify(this.selectedTimeslots)
    );
    this.scheduleData.append("week", this.selectedSchedule.week.toString());
    this.deals.addScheduleToDeal(dealId, this.scheduleData).subscribe((res) => {
      // console.log(res);
      this.router.navigate(["/deals/"]);
    });
  }

  private editDealSchedule(dealId: number): void {
    this.onScheduleChange();
    if (this.scheduleData.has("timeslots")) {
      this.scheduleData.delete("timeslots");
    }
    this.scheduleData.append(
      "timeslots",
      JSON.stringify(this.selectedTimeslots)
    );

    if (this.scheduleData.has("valid_from")) {
      this.scheduleData.delete("valid_from");
    }
    this.scheduleData.append("valid_from", this.selectedSchedule.valid_from);

    if (this.scheduleData.has("valid_until")) {
      this.scheduleData.delete("valid_until");
    }
    this.scheduleData.append("valid_until", this.selectedSchedule.valid_until);
    this.formdata.setFormData(
      "week",
      this.selectedSchedule.week.toString(),
      this.scheduleData
    );
    this.deals
      .editDealSchedule(dealId, this.scheduleData)
      .subscribe((res) => {});
  }

  private processPrice(): string {
    if (this.formData.has("price")) {
      this.formData.delete("price");
    }
    return this.selectedDeal.price.replace(".", ",");
  }

  private processOriginalPrice(): string {
    if (this.formData.has("original_price")) {
      this.formData.delete("original_price");
    }
    return this.selectedDeal.original_price.replace(".", ",");
  }

  public onFileChange(event) {
    const fileList: FileList = event.target.files;
    const file: File = fileList[0];
    this.formData.append("image", file);

    this.fileToUpload = file;
    const reader = new FileReader();
    reader.onload = (event2: any) => {
      this.imageUrl = event2.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  public createDeal(): void {
    this.formData.append("title", this.selectedDeal.title);
    this.formData.append("price", this.processPrice());
    this.formData.append("original_price", this.processOriginalPrice());

    if (typeof this.selectedDeal.description === "undefined") {
      this.selectedDeal.description = "";
    }

    this.formData.append("description", this.selectedDeal.description);
    this.formData.append("barcode", this.selectedDeal.barcode);
    this.formData.append("categories", JSON.stringify(this.selectedCategories));

    this.formData.append("image", this.fileToUpload);
    // console.log(this.formData.get('categories'));
    // Create deal
    this.deals.createDeal(this.formData).subscribe((createDeal) => {
      this.formData = new FormData();
      this.addScheduleToDeal(createDeal.id);
    });
    this.formData = new FormData();
  }

  public editDeal(): void {
    if (this.selectedLang === "en") {
      const translations = [
        {
          lang: "en",
          title: this.selectedDeal.translation_title,
          description: this.selectedDeal.translation_description,
        },
      ];
      if (this.formData.has("translations")) {
        this.formData.delete("translations");
      }
      this.formData.append("translations", JSON.stringify(translations));
    }
    this.formData.append("title", this.selectedDeal.title);
    this.formData.append("price", this.processPrice());
    this.formData.append("original_price", this.processOriginalPrice());

    if (this.selectedDeal.description === "undefined") {
      this.selectedDeal.description = "";
    }

    this.formData.append("description", this.selectedDeal.description);
    this.formData.append("barcode", this.selectedDeal.barcode);

    // Edit Schedule.
    this.editDealSchedule(this.selectedDeal.id);

    // Add Categories.
    this.selectedCategories.forEach((catNum) => {
      if (
        !this.selectedCategories ||
        !this.selectedDeal.categories.some((x) => x.id == catNum)
      ) {
        this.deals
          .addCategoryToDeal(this.selectedDeal.id, catNum)
          .subscribe((categoryResponse) => {});
      }
    });

    // Remove categories if necessary
    this.selectedDeal.categories.forEach((category) => {
      if (!this.selectedCategories.some((catNum) => catNum == category.id)) {
        this.deals
          .removeCategoryFromDeal(this.selectedDeal.id, category.id)
          .subscribe((categoryResponse) => {});
      }
    });

    // Edit deal
    this.deals.editDeal(this.selectedDeal.id, this.formData).subscribe(
      (res) => {
        this.successMessage = "De deal is aangepast!";
        this.getSelectedDeal();
      },
      (error) => {
        this.errorMessage = error.error.message;
      }
    );
    this.formData = new FormData();
    this.getSelectedDeal();
  }

  public onSubmit(form: NgForm) {
    if (this.isNewDeal) {
      this.createDeal();
    } else {
      this.editDeal();
    }
  }

  public openDeleteModal(content): void {
    this.openedModal = this.modalService.open(content);
  }

  public deleteDeal(): void {
    this.deals.deleteDeal(this.selectedDeal.id).subscribe((res) => {
      this.openedModal.close();
      this.router.navigate(["/deals"]);
    });
  }

  public switchLang(): void {
    this.selectedDeal.translation_title = this.lang.getDealTranslationTitle(
      this.selectedLang,
      this.selectedDeal
    );
    this.selectedDeal.translation_description =
      this.lang.getDealTranslationDescription(
        this.selectedLang,
        this.selectedDeal
      );
  }
}
