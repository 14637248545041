import { Injectable } from '@angular/core';
// import {getType} from '@angular/core/src/errors';

@Injectable()
export class FormdataService {

  constructor() { }

  public setFormData(key: string, value: any, data: FormData) {
    if (data.has(key)) {
      data.delete(key);
    }
    if (typeof value !== 'string' && typeof value !== 'number') {
      value = JSON.stringify(value);
    }
    data.append(key, value);
    return data;
  }

  public proccessDaysForApi(dayList): any[] {
    const dayArray = [];
    dayList.forEach(day => {
      if (day.opens || day.closes) {
        const dayObj: any = {
          daynum: day.daynum,
        };
        if (day.opens) {
          dayObj.opens = day.opens.toString();
        }
        if (day.closes) {
          dayObj.closes = day.closes.toString();
        }
        if (day.closed) {
          dayObj.status = 'closed';
        } else {
          dayObj.status = 'open';
        }
        dayArray.push(dayObj);
      }
    });
    console.log(dayArray);
    return dayArray;
  }
}
