import { Component, OnInit } from '@angular/core';
import {Store} from '../../models/stores/store';
import {ApiService} from '../../services/api/api.service';

@Component({
  selector: 'app-winkels',
  templateUrl: './winkels.component.html',
  styleUrls: ['./winkels.component.scss']
})
export class WinkelsComponent implements OnInit {
  storeList: Store[];
  constructor(
    private api: ApiService
  ) { }

  ngOnInit() {
    this.getStoreList();
  }

  public getStoreList(): void {
    this.api.getStores().subscribe( stores => {
      this.storeList = stores;
      // console.log(this.storeList);
    });
  }
}
