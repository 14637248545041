import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiService} from '../../services/api/api.service';
import {AuthService} from '../../services/auth/auth.service';
import {FormGroup, NgForm} from '@angular/forms';
import {LoginModel} from '../../models/users/user';
import {Observable} from 'rxjs/Observable';
import { shop } from '@env/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  model: LoginModel = new LoginModel();
  errorMessage: string;
  shop = shop;
  constructor(
    private api: ApiService,
    private auth: AuthService,
  ) { }

  ngOnInit() {}

  submitForm(form: NgForm): void {
    this.loginUser();
  }
  // Todo: remove password from template :P.
  loginUser() {
    this.auth.login(this.model.email, this.model.password).subscribe(res => {
      console.log(res);
    }, error => {
      if (error.error.error === 'invalid_credentials') {
        this.errorMessage = 'De ingevoerde gegevens zijn onjuist.';
      } else {
        this.errorMessage = error.error.message;
      }
    });
  }
}
