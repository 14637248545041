import { Injectable } from '@angular/core';
import {SparUser} from '../../models/users/user';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class UsersService {
  constructor(private api: ApiService) { }

  public getUserById(id: number): Observable<SparUser> {
    return this.api.get('/api/users/' + id);
  }
}
