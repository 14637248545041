import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DealsService } from '../../../services/deals/deals.service';
import { Timeslot } from '../../../models/timeslots/timeslots';
import { FormdataService } from '../../../services/formdata/formdata.service';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tijdsbestek-aanpassen',
  templateUrl: './tijdsbestek-aanpassen.component.html',
  styleUrls: ['./tijdsbestek-aanpassen.component.scss']
})
export class TijdsbestekAanpassenComponent implements OnInit {
  selectedTimeslot: Timeslot = new Timeslot();
  formData: FormData = new FormData();
  successMessage: string;
  errorMessage: string;
  isNewTimeslot: boolean;
  openedModal: any;

  constructor(
    private route: ActivatedRoute,
    private deals: DealsService,
    private formdata: FormdataService,
    private router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
    if (this.router.url === '/tijdsbestek-aanmaken') {
      this.isNewTimeslot = true;
    } else {
      this.isNewTimeslot = false;
      this.getSelectedTimeslot();
    }
  }


  public getSelectedTimeslot(): void {
    this.route.params.subscribe(params => {
      const paramId = + params['id'];
      this.deals.getTimeslotByID(paramId).subscribe(timeslot => {
        this.selectedTimeslot = timeslot;
      });
    });
  }

  public editTimeslot(): void {
    this.formData.append('title', this.selectedTimeslot.title);
    this.formData.append('from', this.selectedTimeslot.from);
    this.formData.append('to', this.selectedTimeslot.to);
    this.formdata.setFormData('_method', 'PUT', this.formData);

    this.deals.editTimeslot(this.selectedTimeslot.id, this.formData).subscribe(res => {
      this.successMessage = 'Het Tijdsbestek is aangepast!';
      this.formData = new FormData();
      this.getSelectedTimeslot();
    });
  }


  public addTimeslot(): void {
    // Append newCategory content as FormData.
    this.formData.append('title', this.selectedTimeslot.title);
    this.formData.append('from', this.selectedTimeslot.from);
    this.formData.append('to', this.selectedTimeslot.to);
    // Send formdata to api.
    this.deals.createTimeslot(this.formData).subscribe(res => {
      console.log(res);
      this.router.navigate(['/tijdsbestek']);
    }, error => {
      this.errorMessage = error.error.message;
    });
  }


  public deleteTimeslot(): void {
    this.deals.deleteTimeslot(this.selectedTimeslot.id).subscribe(res => {
      this.openedModal.close();
      this.router.navigate(['/tijdsbestek']);
    });
  }

  public openDeleteModal(content) {
    this.openedModal = this.modalService.open(content);
  }

  public submitForm(form: NgForm): void {
    if (this.isNewTimeslot) {
      this.addTimeslot();
    } else {
      this.editTimeslot();
    }
  }
}
