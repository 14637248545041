import { Component, OnInit } from '@angular/core';
import { BusinessHour, Store } from '../../../models/stores/store';
import { ActivatedRoute, Router } from '@angular/router';
import { StoresService } from '../../../services/store/stores.service';
import { NgForm } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormdataService } from '../../../services/formdata/formdata.service';
import { ScheduleDay } from '../../../models/deals/deal';
import { DeviatinghoursService } from '../../../services/deviatinghours/deviatinghours.service';
import { environment } from '../../../../environments/environment';
@Component({
  selector: 'app-winkels-aanpassen',
  templateUrl: './winkels-aanpassen.component.html',
  styleUrls: ['./winkels-aanpassen.component.scss']
})
export class WinkelsAanpassenComponent implements OnInit {
  isNewStore: boolean;
  formData: FormData = new FormData();
  selectedStore: Store = new Store();
  selectedBusinessHours: BusinessHour[] = [];
  regularBusinessHours: BusinessHour[] = [];
  dayList: BusinessHour[] = [];
  realDayList: BusinessHour[] = [];
  backupDayList: BusinessHour[] = [];
  deviatingHours = [];
  fileToUpload: File;
  imageUrl: string;
  selectedDay = 1;
  selectedDeviating;
  openedModal: any;
  displayAddDeviating: false;
  errorMessage: string;
  successMessage: string;
  newDeviating = {
    date: '',
    from: '',
    to: '',
    status: null,
    reason: 'test',
  };

  deviatingError = {
    display: false,
    message: '',
  };

  enableDeviating = environment.deviatingHours;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private stores: StoresService,
    private modalService: NgbModal,
    private formdata: FormdataService,
    private deviatinghours: DeviatinghoursService
  ) { }

  ngOnInit() {
    if (this.router.url === '/winkels-aanmaken') {
      // New deal.
      this.isNewStore = true;
      console.log('create new deal');
      this.newDayList();
    } else {
      // Edit deal.
      this.isNewStore = false;
      this.getSelectedStore();

    }
  }

  public getSelectedStore(): void {
    this.route.params.subscribe(params => {
      const paramId = +params['id'];
      this.stores.getStoreById(paramId).subscribe(res => {
        this.selectedStore = res;
        this.imageUrl = this.selectedStore.store_image;
        console.log(this.selectedStore);
        this.selectedBusinessHours = this.selectedStore.businesshours;
        this.regularBusinessHours = this.selectedStore.regularBusinessHours;
        this.createDayList();
        this.getDeviatinghours();

      });
    });
  }

  public newDayList(): void {
    this.dayList = [];
    this.realDayList = [];
    for (let dayCounter = 1; dayCounter < 8; dayCounter++) {
      let day = new BusinessHour(dayCounter);
      day.daynum = dayCounter;
      this.dayList.push(day);
    }
  }

  // Todo: Make these functions (getDay, processDaysForApi) less... weird.
  public createDayList(): void {
    for (let dayCounter = 1; dayCounter < 8; dayCounter++) {
      const day = this.getRegularDay(dayCounter);
      this.dayList.push(day);
      this.backupDayList.push(Object.assign({}, day));

      const realDay = this.getDay(dayCounter);
      this.realDayList.push(realDay);
    }
  }

  public getRegularDay(dayNum: number): BusinessHour {
    let day;
    this.regularBusinessHours.forEach(storeDay => {
      if (storeDay.daynum === dayNum) {
        day = storeDay;
      }
    });
    if (!day) {
      day = new BusinessHour(dayNum);
    }
    return day;
  }

  public getDay(dayNum: number): BusinessHour {
    let day;
    this.selectedBusinessHours.forEach(storeDay => {
      if (storeDay.daynum === dayNum) {
        day = storeDay;
      }
    });
    if (!day) {
      day = new BusinessHour(dayNum);
    }
    return day;
  }

  // Todo: if time add onFileChange to formdata service.
  public onFileChange(event) {
    if (this.formData.has('store_image')) {
      this.formData.delete('store_image');
    }
    const fileList: FileList = event.target.files;
    const file: File = fileList[0];
    this.formData.append('store_image', file);

    this.fileToUpload = file;
    const reader = new FileReader();
    reader.onload = (event2: any) => {
      this.imageUrl = event2.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }

  public createStore(): void {
    this.collectFormData(true);
    this.stores.createStore(this.formData).subscribe(res => {
      // console.log(res);
      this.formData = new FormData();
      this.router.navigate(['/winkels']);
    });
  }

  public addDeviatinghours(): void {

    console.log(this.newDeviating);

    if (this.newDeviating.status == true) {
        this.newDeviating.status = 'closed';
    } else {
      this.newDeviating.status = 'open';
    }


    this.deviatinghours.addDeviatinghours(this.selectedStore.id, this.newDeviating).subscribe(res => {
      this.displayAddDeviating = false;

      this.deviatingError.display = false;
      this.deviatingError.message = '';
    }, error => {
        this.deviatingError.display = true;
        this.deviatingError.message = 'Aanmaken niet gelukt controleer alle parameters en probeer het nog een keer';
    });
  }

  public deleteDeviatinghours(deviatingID: number): void {
    this.deviatinghours.deleteDeviatinghours(this.selectedStore.id, deviatingID).subscribe(res => {
      this.selectedDeviating = null;
      this.getDeviatinghours();
    });
  }

  public getDeviatinghours(): void {
    this.deviatinghours.getDeviatinghours(this.selectedStore.id).subscribe(res => {
      this.deviatingHours = res;
    });

    return;
  }

  public updateStore(): void {
    this.collectFormData(false);
    this.formdata.setFormData('_method', 'PUT', this.formData);
    this.stores.updateStore(this.selectedStore.id, this.formData).subscribe(res => {
      this.formData = new FormData();
      this.dayList = [];
      this.errorMessage = null;
      this.successMessage = "Winkel aangepast.";
      this.getSelectedStore();
    }, err => {
      this.errorMessage = "Er is iets fout gegaan, controleer de velden en probeer opnieuw."
    });
  }

  private collectFormData(newStore: boolean): void {
    // console.log(this.selectedStore.url);
    this.formdata.setFormData('name', this.selectedStore.name, this.formData);
    this.formdata.setFormData('url', this.selectedStore.url, this.formData);
    // Todo: Api fix pls.
    this.formdata.setFormData('description', this.selectedStore.name, this.formData);
    this.formdata.setFormData('address', this.selectedStore.address, this.formData);
    this.formdata.setFormData('zip', this.selectedStore.zip, this.formData);
    this.formdata.setFormData('city', this.selectedStore.city, this.formData);
    this.formdata.setFormData('email', this.selectedStore.email, this.formData);
    this.formdata.setFormData('lat', this.selectedStore.lat, this.formData);
    this.formdata.setFormData('lon', this.selectedStore.lon, this.formData);
    this.formdata.setFormData('phonenumber', this.selectedStore.phonenumber.toString(), this.formData);
    this.formdata.setFormData('businesshours', JSON.stringify(this.formdata.proccessDaysForApi(this.dayList)), this.formData);
  }

  public logDayList(): void {
    // console.log(this.dayList);
  }

  public onSubmit(form: NgForm): void {
    if (this.router.url === '/winkels-aanmaken') {
      this.createStore();
    } else {
      this.updateStore();
    }
  }

  public deleteCategory(): void {
    this.stores.deleteStore(this.selectedStore.id).subscribe(res => {
      this.openedModal.close();
      this.router.navigate(['/winkels']);
    });
  }

  public openDeleteModal(content) {
    this.openedModal = this.modalService.open(content);
  }
}
