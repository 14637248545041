import { Injectable } from '@angular/core';
import {ApiService} from '../api/api.service';
import {Observable} from 'rxjs/Observable';
import {Actie, ActieSchedule} from '../../models/acties/actie';
import {Category} from '../../models/categories/category';
import {Timeslot} from '../../models/timeslots/timeslots';

@Injectable()
export class ActiesService {

  constructor(
    private api: ApiService
  ) { }

  public getActieById(id: number): Observable<Actie> {
    return this.api.get('/v2/actions/' + id);
  }

  public getCategoriesByActieId(id: number): Observable<Category[]> {
    return this.api.get('/v2/actions/' + id + '/category');
  }

  public getScheduleByActieId(id: number): Observable<ActieSchedule> {
    return this.api.get('/v2/actions/' + id + '/schedule');
  }

  public createActie(formData: any): Observable<any> {
    return this.api.post('/v2/actions', formData);
  }

  public deleteActie(actieId: number): Observable<any> {
    return this.api.delete('/v2/actions/' + actieId);
  }

  public addCategoryToActie(actieId: number, catId: number): Observable<any> {
    return this.api.post('/v2/actions/' + actieId + '/category', {category: catId});
  }

  public addScheduleToActie(actieId: number, formData: any) {
    return this.api.post('/v2/actions/' + actieId + '/schedule', formData);
  }

  public getTimeslots(): Observable<Timeslot[]> {
    return this.api.get('/api/schedules/timeslots');
  }

  public getTimeslotByID(id: number): Observable<Timeslot> {
    return this.api.get('/api/schedules/timeslot/' + id);
  }

  public createTimeslot(formData: any){
    return this.api.post('/api/schedules/timeslots', formData); 
  }
  
  public deleteTimeslot(timeslotId: number): Observable<any> {
    return this.api.delete('/api/schedules/timeslot/' + timeslotId);
  }

  public editTimeslot(timeslotId: number, formData:any): Observable<any> {
    return this.api.post('/api/schedules/timeslot/' + timeslotId, formData);
  }

  public editActie(actieId: number, formData: any): Observable<any> {
    return this.api.post('/v2/actions/' + actieId + '/edit', formData);
  }

  public editActieSchedule(actieId: number, formData: any): Observable<any> {
    return this.api.post('/v2/actions/' + actieId + '/schedule/edit', formData);
  }

  public setPinStatus(actieId: number, status: number): Observable<any> {
    return this.api.post('/v2/actions/' + actieId + '/setpin', {status: status});
  }
}
