import {Component, OnInit} from '@angular/core';
import {CategoryService} from '../../../services/category/category.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Category} from '../../../models/categories/category';
import {NgForm} from '@angular/forms';
import * as $ from 'jquery';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LangService} from '../../../services/lang/lang.service';

@Component({
  selector: 'app-categorie-aanpassen',
  templateUrl: './categorie-aanpassen.component.html',
  styleUrls: ['./categorie-aanpassen.component.scss']
})
export class CategorieAanpassenComponent implements OnInit {
  selectedLang: string;
  isNewCat: boolean;
  selectedCat: Category = new Category();
  formData: FormData = new FormData();
  errorMessage: string;
  successMessage: string;
  imageUrl: string;
  openedModal: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private categories: CategoryService,
    private modalService: NgbModal,
    private lang: LangService
  ) { }

  ngOnInit() {
    this.selectedLang = 'nl';
    if (this.router.url === '/categorie-aanmaken') {
      this.isNewCat = true;
    } else {
      this.isNewCat = false;
      this.getSelectedCategory();
    }
  }

  public switchLang(): void {
    // console.log('Lang: ' + this.selectedLang);
    this.selectedCat.translation_title = this.lang.getCategoryTranslationTitle(this.selectedLang, this.selectedCat);
  }

  public getSelectedCategory(): void {
    this.route.params.subscribe(params => {
      const paramId = +params['id'];
      this.categories.getCategoryById(paramId).subscribe(cat => {
        this.selectedCat = cat;
        this.imageUrl = this.selectedCat.image;
        this.selectedCat.translation_title = this.lang.getCategoryTranslationTitle(this.selectedLang, this.selectedCat);
      });
    });
  }

  public onFileChange(event) {
    const fileList: FileList = event.target.files;
    const file: File = fileList[0];
    this.formData.append('image', file);

    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  public editCategory(): void {
    if (this.selectedLang === 'en') {
      const translations = [{
          lang: 'en',
          title: this.selectedCat.translation_title
        }];

      this.formData.append('translations', JSON.stringify(JSON.stringify(translations)));
    }
    this.formData.append('title', this.selectedCat.title);
    this.formData.append('subscribeable', this.selectedCat.subscribeable.toString());

    this.categories.editCategoriesFormData(this.selectedCat.id, this.formData).subscribe(res => {
      this.successMessage = 'De categorie is aangepast!';
      this.formData = new FormData();
      this.getSelectedCategory();
    });
  }

  public addCategory(): void {
    // Append newCategory content as FormData.
    this.formData.append('title', this.selectedCat.title);

    if (!this.selectedCat.subscribeable) {
      this.selectedCat.subscribeable = 0;
    }

    this.formData.append('subscribeable', this.selectedCat.subscribeable.toString());
    this.formData.append('translations', JSON.stringify([{'lang': 'en', 'title': 'English'}]));
    // Send formdata to api.
    this.categories.createCategories(this.formData).subscribe(res => {
      this.router.navigate(['/categorieen']);
    }, error => {
      this.errorMessage = error.error.message;
    });
  }

  public submitForm(form: NgForm): void {
    if (this.isNewCat) {
      this.addCategory();
    } else {
      this.editCategory();
    }
  }

  public deleteCategory(): void {
    this.categories.deleteCategories(this.selectedCat.id).subscribe(res => {
      this.openedModal.close();
      this.router.navigate(['/categorieen']);
    });
  }

  public openDeleteModal(content) {
    this.openedModal = this.modalService.open(content);
  }
}
