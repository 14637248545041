export const shop = "uni";

export const environment = {
  production: true,
  apiUrl: "https://api.dashboard.sparuniversity.nl",
  onesignalUrl: "https://onesignal.com/api/v1/notifications",
  onesignalApiKey: "ODMxMDljOWYtYTBjNi00OTM0LTg4YjctYWVkOTMzM2QzZGM4",
  onesignalAppKey: "62f1b45f-98d7-4305-a611-ee4e2f6192f1",
  shop,
};
