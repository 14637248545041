import { Category, CategoryTranslations } from "../categories/category";
import { Timeslot } from "../timeslots/timeslots";

export class Deal {
  private _barcode: string;
  private _description: string;
  private _created_at: string;
  private _display: number;
  private _id: number;
  private _image: string;
  private _original_price: string;
  private _pinned: number;
  private _price: string;
  private _schedule_id: number;
  private _schedule: DealSchedule;
  private _title: string;
  private _updated_at: string;
  private _categories: Category[];
  private _all_translations: DealTranslation[];
  private _translation_title: string;
  private _translation_description: string;
  private _translations: any;

  get barcode(): string {
    return this._barcode;
  }

  set barcode(value: string) {
    this._barcode = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get created_at(): string {
    return this._created_at;
  }

  set created_at(value: string) {
    this._created_at = value;
  }

  get display(): number {
    return this._display;
  }

  set display(value: number) {
    this._display = value;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get image(): string {
    return this._image;
  }

  set image(value: string) {
    this._image = value;
  }

  get original_price(): string {
    return this._original_price;
  }

  set original_price(value: string) {
    this._original_price = value;
  }

  get pinned(): number {
    return this._pinned;
  }

  set pinned(value: number) {
    this._pinned = value;
  }

  get price(): string {
    return this._price;
  }

  set price(value: string) {
    this._price = value;
  }

  get schedule_id(): number {
    return this._schedule_id;
  }

  set schedule_id(value: number) {
    this._schedule_id = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get updated_at(): string {
    return this._updated_at;
  }

  set updated_at(value: string) {
    this._updated_at = value;
  }

  get categories(): Category[] {
    return this._categories;
  }

  set categories(value: Category[]) {
    this._categories = value;
  }

  get schedule(): DealSchedule {
    return this._schedule;
  }

  set schedule(value: DealSchedule) {
    this._schedule = value;
  }

  get all_translations(): DealTranslation[] {
    return this._all_translations;
  }

  set all_translations(value: DealTranslation[]) {
    this._all_translations = value;
  }

  get translation_title(): string {
    return this._translation_title;
  }

  set translation_title(value: string) {
    this._translation_title = value;
  }

  get translation_description(): string {
    return this._translation_description;
  }

  set translation_description(value: string) {
    this._translation_description = value;
  }

  get translations(): any {
    return this._translations;
  }

  set translations(value: any) {
    this._translations = value;
  }
}

export class DealSchedule {
  private _id: number;
  private _valid_from: string;
  private _valid_until: string;
  private _week: number;
  private _last_run: string;
  private _current_week: number;
  private _created_at: string;
  private _updated_at: string;
  private _days: ScheduleDay[];
  private _timeslots: Timeslot[];

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get valid_from(): string {
    return this._valid_from;
  }

  set valid_from(value: string) {
    this._valid_from = value;
  }

  get valid_until(): string {
    return this._valid_until;
  }

  set valid_until(value: string) {
    this._valid_until = value;
  }

  get week(): number {
    return this._week;
  }

  set week(value: number) {
    this._week = value;
  }

  get last_run(): string {
    return this._last_run;
  }

  set last_run(value: string) {
    this._last_run = value;
  }

  get current_week(): number {
    return this._current_week;
  }

  set current_week(value: number) {
    this._current_week = value;
  }

  get created_at(): string {
    return this._created_at;
  }

  set created_at(value: string) {
    this._created_at = value;
  }

  get updated_at(): string {
    return this._updated_at;
  }

  set updated_at(value: string) {
    this._updated_at = value;
  }

  get days(): ScheduleDay[] {
    return this._days;
  }

  set days(value: ScheduleDay[]) {
    this._days = value;
  }

  get timeslots(): Timeslot[] {
    return this._timeslots;
  }

  set timeslots(value: Timeslot[]) {
    this._timeslots = value;
  }
}

export class ScheduleDay {
  private _id: number;
  private _daynum: number;
  private _schedule_id: number;

  constructor(dayNum: number) {
    this.daynum = dayNum;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get daynum(): number {
    return this._daynum;
  }

  set daynum(value: number) {
    this._daynum = value;
  }

  get schedule_id(): number {
    return this._schedule_id;
  }

  set schedule_id(value: number) {
    this._schedule_id = value;
  }
}

export class DealTranslation {
  private _id: number;
  private _lang: string;
  private _title: string;
  private _description: string;

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get lang(): string {
    return this._lang;
  }

  set lang(value: string) {
    this._lang = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }
}
