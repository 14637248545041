// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const shop = "uni";

export const environment = {
  production: false,
  apiUrl: "https://api.dashboard.sparuniversity.nl",
  onesignalUrl: "https://onesignal.com/api/v1/notifications",
  onesignalApiKey: "ODMxMDljOWYtYTBjNi00OTM0LTg4YjctYWVkOTMzM2QzZGM4",
  onesignalAppKey: "62f1b45f-98d7-4305-a611-ee4e2f6192f1",
  deviatingHours: true,
  shop,
  weeksAD: false,
  userlistLimit: 25,
  actionCRUD: true,
};
