import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {environment} from '../../../environments/environment';

@Injectable()
export class ApiService {
  apiUrl: string = environment.apiUrl;
  constructor(
    public http: HttpClient,
  ) { }

  public get(url: string, options: object = {}): Observable<any> {
    return this.http.get(
      this.apiUrl + url,
      options
    );
  }

  public post(url: string, body: object = {}, options: object = {}): Observable<any> {
    return this.http.post(
      this.apiUrl + url,
      body,
      options
    );
  }

  public delete(url: string, options: object = {}): Observable<any> {
    return this.http.delete(
      this.apiUrl + url,
      options
    );
  }

  public deleteUrlEncode(url: string, options: object = {}): Observable<any> {

    const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded'
    }),
    body: options
  };
    return this.http.delete(
      this.apiUrl + url,
      httpOptions
    );
  }
  public authenticate(email: string, password: string): Observable<any> {
    return this.http.post(environment.apiUrl + '/oauth/token', {
      client_id: 2,
      client_secret: 'LQ3ia66ebXuXmY4n87CNsj1WwFljqLmk9b30e49k',
      grant_type: 'password',
      username: email,
      password: password
    }, {});
  }

  public refreshAuthToken(): Observable<any> {
    return this.http.post(this.apiUrl + '', {}, {});
  }

  public getStores(): Observable<any> {
    return this.http.get(this.apiUrl + '/api/stores', {});
  }

  public getUsers(offset: number = 0): Observable<any> {
    return this.http.get(this.apiUrl + `/api/users?offset=${offset}&limit=${environment.userlistLimit}`, {});
  }

  public getDeals(): Observable<any> {
    return this.http.get(this.apiUrl + '/api/deals', {});
  }

  public getActies(): Observable<any> {
    return this.http.get(this.apiUrl + '/v2/actions', {});
  }

  public getClaims(): Observable<any> {
    return this.http.get(this.apiUrl + '/api/claims', {});
  }

  public getClaimsTotal(): Observable<any> {
    return this.http.get(this.apiUrl + '/api/claims/total', {});
  }

  public updateWeight(id, weight): Observable<any> {
    return this.http.post(this.apiUrl + '/v2/actions/' + id + '/weight', {'weight': weight});
  }
}
