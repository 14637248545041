import { Component, OnInit } from '@angular/core';
import {SparUser} from '../../../models/users/user';
import {UsersService} from '../../../services/users/users.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-gebruiker-details',
  templateUrl: './gebruiker-details.component.html',
  styleUrls: ['./gebruiker-details.component.scss']
})
export class GebruikerDetailsComponent implements OnInit {
  selectedUser: SparUser = new SparUser();
  constructor(
    private route: ActivatedRoute,
    private users: UsersService
  ) { }

  ngOnInit() {
    this.getSelectedUser();
  }

  public getSelectedUser(): void {
    this.route.params.subscribe(params => {
      const paramId = +params['id'];
      this.users.getUserById(paramId).subscribe(res => {
        this.selectedUser = res;
      });
    });
  }
}
