export class Store {
  private _id: number;
  private _name: string;
  private _store_image: string;
  private _description: string;
  private _address: string;
  private _zip: string;
  private _city: string;
  private _phonenumber: string;
  private _lat: string;
  private _lon: string;
  private _email: string;
  private _businesshours: BusinessHour[];
  private _regularBusinessHours: BusinessHour[];
  private _url: string;

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get store_image(): string {
    return this._store_image;
  }

  set store_image(value: string) {
    this._store_image = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get address(): string {
    return this._address;
  }

  set address(value: string) {
    this._address = value;
  }

  get zip(): string {
    return this._zip;
  }

  set zip(value: string) {
    this._zip = value;
  }

  get city(): string {
    return this._city;
  }

  set city(value: string) {
    this._city = value;
  }

  get phonenumber(): string {
    return this._phonenumber;
  }

  set phonenumber(value: string) {
    this._phonenumber = value;
  }

  get lat(): string {
    return this._lat;
  }

  set lat(value: string) {
    this._lat = value;
  }

  get lon(): string {
    return this._lon;
  }

  set lon(value: string) {
    this._lon = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get businesshours(): BusinessHour[] {
    return this._businesshours;
  }

  set businesshours(value: BusinessHour[]) {
    this._businesshours = value;
  }

  get regularBusinessHours(): BusinessHour[] {
    return this._regularBusinessHours;
  }

  set regularBusinessHours(value: BusinessHour[]) {
    this._regularBusinessHours = value;
  }
}

export class BusinessHour {
  private _id: number;
  private _store_id: number;
  private _daynum: number;
  private _opens: string;
  private _closes: string;
  private _status: string;
  private _closed: boolean;
  private _type: string;

  constructor(dayNum: number) {
    this.id = dayNum;
    let dayName: string;
    switch (dayNum) {
      case 1:
        dayName = 'Monday';
        break;
      case 2:
        dayName = 'Tuesday';
        break;
      case 3:
        dayName = 'Wednesday';
        break;
      case 4:
        dayName = 'Thursday';
        break;
      case 5:
        dayName = 'Friday';
        break;
      case 6:
        dayName = 'Saturday';
        break;
      case 7:
        dayName = 'Sunday';
        break;
    }
    this.type = dayName;
    this.daynum = dayNum;
  }

  get id(): number {
    return this._id;
  }

  set id(value: number) {
    this._id = value;
  }

  get store_id(): number {
    return this._store_id;
  }

  set store_id(value: number) {
    this._store_id = value;
  }

  get daynum(): number {
    return this._daynum;
  }

  set daynum(value: number) {
    this._daynum = value;
  }

  get opens(): string {
    return this._opens;
  }

  set opens(value: string) {
    this._opens = value;
  }

  get closes(): string {
    return this._closes;
  }

  set closes(value: string) {
    this._closes = value;
  }

  get status(): string {
    return this._status;
  }

  set status(value: string) {
    this._status = value;
  }

  get closed(): boolean {
    return this._closed;
  }

  set closed(value: boolean) {
    this._closed = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }
}
