import { Injectable } from '@angular/core';
import { Store } from '../../models/stores/store';
import { ApiService } from '../api/api.service';
import {Observable} from 'rxjs/Observable';

@Injectable()
export class DeviatinghoursService {

  constructor(
    private api: ApiService
  ) { }

  public getDeviatinghours(storeID: number): Observable<any> {
    return this.api.get('/api/stores/' + storeID + '/businesshours/deviating');
  }

  public addDeviatinghours(storeID: number, deviatingHour: any): Observable<any> {
    return this.api.post('/api/stores/' + storeID + '/businesshours/deviating', deviatingHour);
  }

  public deleteDeviatinghours(storeID: number, deviatingID: number): Observable<any> {
    return this.api.delete('/api/stores/' + storeID + '/businesshours/deviating?deviatingID=' + deviatingID.toString());
  }
}
